 <template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Asset types -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid">
                        <div class="col-4">
                            <Button :class="showActive ? 'w-full' : 'w-full p-button-outlined'" label="Active Assets" :icon="showActive ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showActive=!showActive" />
                        </div>
                        <div class="col-4">
                            <Button :class="showInactive ? 'w-full' : 'w-full p-button-outlined'" label="Inactive Assets" :icon="showInactive ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showInactive=!showInactive" />
                        </div>
                        <div class="col-4">
                            <Button :class="showProspects ? 'w-full' : 'w-full p-button-outlined'" label="All Prospect" :icon="showProspects ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showProspects=!showProspects" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <div class="flex flex-row justify-content-between">
                        <AutoComplete class="w-full mb-4" v-model="clientSelection" dropdown :suggestions="clientSelectionOptions"
                                      @complete="clientSearch" placeholder="Search for Client"/>
                    </div>

                    <DataTable v-model:selection="assetSelection" :value="assetSelectionOptions" dataKey="name"
                               stripedRows scrollable scrollHeight="300px">
                        <Column selectionMode="multiple" style="flex: 0 0 1rem"></Column>
                        <Column field="name" header="Name"></Column>
                    </DataTable>

                </div>
            </div>

            <!-- Search Type -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <div class="flex flex-row justify-content-between">
                        <p class="flex font-bold capitalize text-left text-base">Search Type</p>
                    </div>

                    <!-- Search type checkboxes -->
                    <div class="grid">
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="searches" inputId="torrent" name="searchType" value="torrent" />
                                <label for="torrent" class="ml-2"> Torrent </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="searches" inputId="organic" name="searchType" value="organic" />
                                <label for="organic" class="ml-2"> Organic </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="searches" inputId="preinject" name="searchType" value="preinject" />
                                <label for="preinject" class="ml-2"> Preinject </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="searches" inputId="preinjectNew" name="searchType" value="preinject_new" />
                                <label for="preinjectNew" class="ml-2"> New Preinjects </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="searches" inputId="preinjectDDownload" name="searchType" value="preinject_ddownload" />
                                <label for="preinjectDDownload" class="ml-2"> Preinject DDownload </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="searches" inputId="preinjectMapped" name="searchType" value="preinject_mapped" />
                                <label for="preinjectMapped" class="ml-2"> Preinject Mapped </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Search Country Selection -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <div class="flex flex-row justify-content-between">
                        <p class="flex font-bold capitalize text-left text-base">Search Countries</p>
                    </div>

                    <!-- Search type checkboxes -->
                    <div class="grid">
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="countries" inputId="all" name="searchCountry" value="all" />
                                <label for="all" class="ml-2"> All Countries </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="countries" inputId="uk" name="searchCountry" value="uk" />
                                <label for="uk" class="ml-2"> United Kingdom </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="countries" inputId="us" name="searchCountry" value="us" />
                                <label for="us" class="ml-2"> United States </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="countries" inputId="au" name="searchCountry" value="au" />
                                <label for="au" class="ml-2"> Australia </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="countries" inputId="jp" name="searchCountry" value="jp" />
                                <label for="jp" class="ml-2"> Japan </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center mr-4">
                                <Checkbox v-model="countries" inputId="ru" name="searchCountry" value="ru" />
                                <label for="ru" class="ml-2"> Russia </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Buttons -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <div class="grid">
                        <div class="col-3">
                            <Button class="w-full" label="Start Now" @click="startNormalPriority" :disabled="!safeOptions()"/>
                        </div>
                        <div class="col-3">
                            <Button class="w-full" label="Low Priority" @click="startLowPriority" :disabled="!safeOptions()"/>
                        </div>
                        <div class="col-3">
                            <Button class="w-full" label="High Priority" @click="startHighPriority" :disabled="!safeOptions()"/>
                        </div>
                        <div class="col-3">
                            <Button class="w-full" label="Schedule" @click="createSchedule" :disabled="!safeOptions()"/>
                        </div>
                        <div class="col-12">
                            <Button class="w-full" label="Start All" disabled/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ScheduleJobs ref="scheduleJobs"/>
</template>

<script>
import ScheduleJobs from "@/components/modals/ScheduleJobs";

export default {
    name: "Standard",
    components: {
        ScheduleJobs
    },

    data() {
        return {
            clients: null,
            activeAssets: this.$store.getters.getActiveAssetData,
            inactiveAssets: this.$store.getters.getInactiveAssetData,
            searches: [],
            countries: ['uk'],

            showActive: true,
            showInactive: false,
            showProspects: false,

            clientSelectionOptions: [],
            clientSelection: null,
            assetSelectionOptions: [],
            assetSelection: [],
        }
    },

    mounted() {
        this.getClients();
    },

    methods: {
        getClients() {
            this.$factory.searchPage.getClients().then(data => {
                this.clients = data;
                // this.clientSelection = this.clients[0];
            })
        },

        clientSearch(event) {
            this.clientSelectionOptions = [];

            if (event.query.length < 3) {
                this.clientSelectionOptions = JSON.parse(JSON.stringify(this.clients));
            } else {
                this.clientSelectionOptions = this.clients.filter(client => {
                    return client.toLowerCase().includes(event.query.toLowerCase())
                })
            }
        },

        safeOptions() {
            return this.assetSelection.length > 0 && this.searches.length > 0 && this.countries.length > 0

        },

        startNormalPriority() {
            let jobData = {
                username: this.$store.getters.getUserInfo.username,
                priority: 5,
                assets: this.assetSelection.map(asset => asset.id),
                searches: this.searches,
                countries: this.countries,
            }
            this.startJob(jobData);
        },

        startHighPriority() {
            let jobData = {
                username: this.$store.getters.getUserInfo.username,
                priority: 10,
                assets: this.assetSelection.map(asset => asset.id),
                searches: this.searches,
                countries: this.countries,
            }
            this.startJob(jobData);
        },

        startLowPriority() {
            let jobData = {
                username: this.$store.getters.getUserInfo.username,
                priority: 1,
                assets: this.assetSelection.map(asset => asset.id),
                searches: this.searches,
                countries: this.countries,
            }
            this.startJob(jobData);
        },

        startJob(jobData) {
            console.debug(jobData);
            this.$factory.jobPage.startJob(jobData).then(data => {
                console.debug(data);
                this.success();
            })
        },

        success() {
            this.$emitter.emit("good-toast", 'Job Started');
            this.clientSelection = null;
            this.assetSelection = [];
            this.searches = [];
            this.countries = ['uk'];
        },

        createSchedule() {
            this.$refs.scheduleJobs.show(this.clientSelection, this.assetSelection, this.searches, this.countries);
        },

        setAssets() {
            this.assetSelectionOptions = [];

            if (this.showActive) {
                this.activeAssets.forEach(
                    asset => {
                        if (asset.client === this.clientSelection && this.showProspects === asset.type.includes('Prospect')) {
                            this.assetSelectionOptions.push(asset)
                        }
                    }
                )
            }
            if (this.showInactive) {
                this.inactiveAssets.forEach(
                    asset => {
                        if (asset.client === this.clientSelection && this.showProspects === asset.type.includes('Prospect')) {
                            this.assetSelectionOptions.push(asset)
                        }
                    }
                )
            }
        }
    },

    watch: {
        clientSelection: function (newVal) {
            if (this.clients.includes(newVal)) {
                this.setAssets();
            }
        },

        showActive: function () {
            console.debug("show active changed")
            this.setAssets();
        },

        showInactive: function () {
            console.debug("show inactive changed")
            this.setAssets();
        },

        showProspects: function () {
            console.debug("show prospects changed")
            this.setAssets();
        }
    }
}
</script>

<style scoped>

</style>
