<template>
<Dialog v-model:visible="visible" modal :header="'Schedule Jobs'" :style="{ width: '50vw' }">
    <!-- Client -->
    <p class="mt-3 font-bold">Client</p>
    <InputText class="w-full" v-model="scheduleData.client" :disabled="'true'"/>

    <!-- Assets -->
    <p class="mt-3 font-bold">Assets</p>
    <InputText class="w-full" v-model="stringAssets" :disabled="'true'"/>

    <!-- Search Types -->
    <p class="mt-3 font-bold">Search Types</p>
    <InputText class="w-full" v-model="stringSearchTypes" :disabled="'true'"/>

    <!-- Countries -->
    <p class="mt-3 font-bold">Countries</p>
    <InputText class="w-full" v-model="stringCountries" :disabled="'true'"/>

    <!-- Priority -->
    <p class="mt-3 font-bold">Priority</p>
    <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleData.priority" inputId="priority1" name="priority1" value="1" />
            <label for="priority1" class="ml-2">Low</label>
        </div>
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleData.priority" inputId="priority2" name="priority2" value="5" />
            <label for="priority2" class="ml-2">Normal (Recommended)</label>
        </div>
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleData.priority" inputId="priority3" name="priority3" value="10" />
            <label for="priority3" class="ml-2">High</label>
        </div>
    </div>

    <!-- Cron -->
    <p class="mt-3 font-bold">Cron</p>
    <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleChoice" inputId="schedule1" name="schedule1" value="weekly" />
            <label for="schedule1" class="ml-2">1xWeek</label>
        </div>
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleChoice" inputId="schedule2" name="schedule2" value="weekly3" />
            <label for="schedule2" class="ml-2">3xWeek</label>
        </div>
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleChoice" inputId="schedule3" name="schedule3" value="daily" />
            <label for="schedule3" class="ml-2">1xDay</label>
        </div>
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleChoice" inputId="schedule4" name="schedule4" value="daily3" />
            <label for="schedule4" class="ml-2">3xDay</label>
        </div>
        <div class="flex align-items-center mr-4">
            <RadioButton v-model="scheduleChoice" inputId="schedule5" name="schedule5" value="custom" />
            <label for="schedule5" class="ml-2">Custom</label>
        </div>
    </div>

    <cron-light v-if="scheduleChoice==='custom'" v-model="scheduleData.schedule" @error="cronError=$event" class="mt-2"/>

    <!-- Start date -->
    <p class="mt-3 font-bold">Start date</p>
    <Calendar id="calendar-24h" v-model="scheduleData.startDate" showTime hourFormat="24" />

    <!-- End date -->
    <p class="mt-3 font-bold">End date</p>
    <Calendar id="calendar-24h" v-model="scheduleData.endDate" showTime hourFormat="24" />


    <template #footer>
        <Button class="w-full mt-2" label="Save" @click="save"
                :disabled="(scheduleData.schedule === '* * * * *' && scheduleChoice === 'custom') ||
                scheduleData.priority === null ||
                scheduleData.startDate === null || scheduleData.startDate === '' ||
                scheduleData.endDate === null || scheduleData.endDate === ''"/>
    </template>


</Dialog>
</template>

<script>
export default {
    name: "ScheduleJobs",

    data() {
        return {
            visible: false,
            scheduleData: {
                client: "",
                assets: [],
                searchTypes: [],
                countries: [],
                priority: 5,
                schedule: "* * * * *",
                startDate: null,
                endDate: null,
            },

            cronError: "",

            stringAssets: "",
            stringSearchTypes: "",
            stringCountries: "",
            scheduleChoice: "weekly",
        }
    },

    methods: {
        init() {
            this.scheduleData = {
                client: "",
                assets: [],
                searchTypes: [],
                countries: [],
                priority: 5,
                schedule: "* * * * *",
                startDate: null,
                endDate: null,
            };

            this.cronError = "";
            this.scheduleChoice = "weekly";
        },

        show(client, assets, searchTypes, countries) {
            this.init();

            this.scheduleData.client = client;
            // get asset id : asset name
            this.scheduleData.assets = assets.filter((el) => el.id + ':' + el.name).map((el) => el.id + ':' + el.name);
            this.scheduleData.searchTypes = searchTypes;
            this.scheduleData.countries = countries;

            this.stringAssets = this.scheduleData.assets.join(", ");
            this.stringSearchTypes = this.scheduleData.searchTypes.join(", ");
            this.stringCountries = this.scheduleData.countries.join(", ");

            console.debug(this.stringAssets);

            this.visible = true;
        },

        randint(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        },

        save() {
            if (this.scheduleChoice !== 'custom') {
                if (this.scheduleChoice === 'weekly') {
                    let min = this.randint(0, 59);
                    let hour = this.randint(0, 23);
                    let day = this.randint(1, 7);
                    this.scheduleData.schedule = `${min} ${hour} * * ${day}`;
                } else if (this.scheduleChoice === 'weekly3') {
                    let min = this.randint(0, 59);
                    let hour = this.randint(0, 23);
                    let day = this.randint(0, 1);
                    this.scheduleData.schedule = `${min} ${hour} * * ${day},${day + 2},${day + 4}`;
                } else if (this.scheduleChoice === 'daily') {
                    let min = this.randint(0, 59);
                    let hour = this.randint(0, 23);
                    this.scheduleData.schedule = `${min} ${hour} * * *`;
                } else if (this.scheduleChoice === 'daily3') {
                    let min = this.randint(0, 59);
                    let hour = this.randint(0, 7);
                    this.scheduleData.schedule = `${min} ${hour},${hour + 7},${hour+14} * * *`;
                }
            }

            this.$factory.schedule.createSchedule(this.scheduleData).then(() => {
                this.$emitter.emit("good-toast", "Successfully added new schedule, it will be processed tomorrow");
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Error while saving schedule");
            }).finally(() => {
                this.visible = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
